<template>
  <div>
    <h1>{{ title }}</h1>
    <p class="mt-4 max-w-xl text-base">
      Here you can access and download all of the images uploaded by users who have personalized content experiences.
    </p>

    <!-- <TextInput
      v-model="query"
      prepend-icon="search"
      placeholder="Search by collection, personalisation, or user name"
      :spellcheck="false"
      class="mt-12 max-w-lg"
    /> -->

    <div class="mt-12 space-y-3">
      <div v-for="item in data" :key="item.id" class="rounded-lg bg-white shadow md:flex">
        <div class="overflow-hidden rounded-t-md md:relative md:w-full md:max-w-xxs md:rounded-none md:rounded-l-md">
          <img :src="item.media.thumb_url" class="aspect-video w-full object-cover md:h-full" />
        </div>
        <div
          class="flex items-center space-x-6 rounded-lg rounded-t-none border border-t-0 p-6 md:flex-grow md:justify-between md:rounded-lg md:rounded-l-none md:border md:border-l-0"
        >
          <div>
            <router-link
              :to="{ name: 'collection', params: { id: item.shared_collection.collection.id } }"
              class="font-semibold"
            >
              {{ item.shared_collection.collection.name }}
            </router-link>
            <div class="flex flex-wrap">
              <a :href="item.personalisation.landing_page_url" target="_blank" class="mr-6 mt-2">
                <Icon icon="bullseye" class="mr-2 text-supplement" /> {{ item.personalisation.name }}
              </a>
              <span class="mr-6 mt-2">
                <Icon icon="user" class="mr-2 text-supplement" /> {{ item.user.full_name }}
              </span>
              <span class="mr-6 mt-2">
                <Icon icon="calendar" class="mr-2 text-supplement" /> {{ item.media.created_at | datetime }}
              </span>
            </div>
          </div>
          <Button as="a" :href="item.media.download_url"><Icon icon="download" /></Button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  metaInfo() {
    return { title: this.title }
  },
  data: () => ({
    title: "Inspires User Uploads",
    data: null
  }),
  async created() {
    const response = await this.$api.get("user-uploads")
    this.data = response.data
  }
}
</script>
